<template>
  <div
    id="CtkSideBar"
    ref="CtkSideBar"
    :class="{
      'hide-side-bar': !open,
      'absolute': absolute,
      'with-shadow': !withoutShadow,
      'right': right
    }"
    class="ctk-side-bar tw-relative tw-flex tw-flex-1 tw-bg-white tw-z-50"
  >
    <transition
      name="fade"
      mode="in-out"
    >
      <div
        class="content tw-flex tw-flex-1 tw-w-full"
      >
        <slot />
      </div>
    </transition>
    <ctk-side-bar-toggle
      v-if="!withoutCloseBtn"
      :open="open"
      :right="right"
      @toggle="toggleMenu"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkSideBarToggle from './_subs/CtkSideBarToggle/index.vue'

  /**
   * Generic component used to show a togglable sidebar (left or right) in the layout
   * @module component - ctkSidebar
   * @param {number} width - The sidebar width
   * @param {boolean} [withoutCloseBtn=false] - Specify if the sidebar should have or not the toggle button
   * @param {boolean} [withoutShadow=false] - Specify if the sidebar should have the drop shadow
   * @param {boolean} [absolute=false] - Specify if the sidebar should be positionned in an absolute way.
   * @param {boolean} [open=false] - Is the sidebar open or not
   * @param {boolean} [right=false] - Specify the sidebar direction, by default the sidebar is positionned in the left side.
   * @emits toggle-menu
   */
  export default defineComponent({
    name: 'CtkSideBar',
    components: {
      CtkSideBarToggle
    },
    props: {
      width: {
        type: Number,
        required: true
      },
      withoutCloseBtn: {
        type: Boolean,
        default: false
      },
      withoutShadow: {
        type: Boolean,
        default: false
      },
      absolute: {
        type: Boolean,
        default: false
      },
      open: {
        type: Boolean,
        default: false
      },
      right: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      toggleMenu () {
        this.$emit('toggle-menu')
      }
    }
  })
</script>

<style lang="scss" scoped>

  #CtkSideBar {
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    transform: translateX(0);
    width: 350px;
    max-width: 350px;
    flex: 0 0 350px;

    @mixin absolute {
      position: absolute !important;
      height: calc(100vh - 55px) !important;
      left: 0;
    }

    @media only screen and (max-width: $breakpoint-laptop-s), only screen and (max-width: $breakpoint-tablet) {
      @include absolute;
    }

    &.with-shadow {
      box-shadow: 2px 1px 8px rgba(0, 0, 0, 0.2);

      &.right {
        box-shadow: -2px -1px 8px rgba(0, 0, 0, 0.2);
      }
    }

    .content {
      opacity: 1;
      transition: opacity 300ms;
    }

    &.hide-side-bar {
      width: 0;
      max-width: 0;
      flex: 0 0 0;

      .content {
        opacity: 0;
        overflow: hidden;
      }
    }

    &.right.hide-side-bar {
      box-shadow: none !important;
      transform: translateX(100%);
    }

    &.absolute {
      left: 0;
      position: absolute;
      height: calc(100vh - 55px);
      max-width: 90%;
    }

    &.right.absolute {
      right: 0;
      left: inherit;
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      &:not(.hide-side-bar):not(.absolute) {
        width: calc(100vw - 40px) !important;
        max-width: calc(100vw - 40px) !important;
      }
    }
  }

</style>
