















import { defineComponent } from '@vue/composition-api'

/**
 * @module component - CtkCollapseCategoryItem
 * @param {string} icon
 * @param {string} title
 */
export default defineComponent({
  name: 'CtkCollapseCategoryItem',
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
})
